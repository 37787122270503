.find-replace-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
  }
  
  .text-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  .controls {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
  }
  
  .controls input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .controls button {
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    background: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .controls button:hover {
    background: #0056b3;
  }