.json-formatter-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .editor-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-grow: 1;
  }
  
  .editor-container > * {
    width: 50%;
  }
  
  button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  